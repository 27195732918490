import React, {useState, useEffect} from "react";
import axios from "axios";
import {
    useParams
  } from 'react-router-dom';
const Detail = () => {
    const [post, setPost] = useState(null);
    const { postId } = useParams();
    React.useEffect(() => {
        axios.get(`https://jsonplaceholder.typicode.com/posts/${postId}`).then((response) => {
          setPost(response.data);
        });
      }, []);

      if (!post) return null;

    return <div>
    <div>
      <h1>{post.title}</h1>
      <p>{post.body}</p>
    </div>
  </div>
};

export default Detail;